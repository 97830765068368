h1 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin: 0.5em 0 0.5em 0;
}

h2 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0.5em 0 0.5em 0;
}

h3 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0.75em 0 0.75em 0;
}

h4 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-style: italic;
  margin: 0.75em 0 0.75em 0;
}

h5 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin: 0.75em 0 0.75em 0;
}

h6 {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  font-style: italic;
  margin: 0.75em 0 0.75em 0;
}

table {
  margin-left:auto; 
  margin-right:auto;
}

td {
  border: 1px dotted grey;
}